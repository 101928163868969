import React from "react"
import { Link, graphql } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { config } from '@fortawesome/fontawesome-svg-core'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

config.autoAddCss = false

const Article = styled.article`
  border-bottom: 1px solid #eee;
  margin: 20px;
  padding: 5px;
  @media screen and (max-width: 780px) {
    margin: 10px;
    padding: 8px;
  }
`

const Header = styled.header`
`

const Title = styled.h3`
    /* margin-top: 6px; */
    margin-bottom: 6px;
`

const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: var(--width);
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  a:link{
        color: inherit;
        text-decoration: none;
  }
  a:visited{
        color: inherit;
        text-decoration: none;
  }
  a:hover{
        color: #4e85a6;
        text-decoration: underline;
  }
  a:active{
        color: inherit;
  }
`

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (

    <Layout location={location} title={siteTitle}>
      <SEO title="ya9do's blog" />
      <Wrapper>
        <div>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <Article>
                <Header>
                  <Title>
                    <Link to={node.fields.slug} >
                      {title}
                    </Link>
                  </Title>
                  <span style={{ color: `#adadad` }}>
                    <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: `6px` }} />
                    {node.frontmatter.date}
                  </span>
                </Header>
                <section>
                  <p style={{ color: `rgb(130, 136, 140)`, fontSize: `14px`, margin: `5px 0px 5px 0px` }}
                    dangerouslySetInnerHTML={{
                      __html: node.excerpt.slice(0, 100),
                    }}
                  />
                </section>
              </Article>
            )
          })}
        </div>
      </Wrapper>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            updated(formatString: "YYYY-MM-DD")
            title
            description
          }
        }
      }
    }
  }
`
